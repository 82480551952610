var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "searchResultContainer",
      on: { click: _vm.emitResultClicked },
    },
    [
      _c("div", { staticClass: "thumb-container" }, [
        _c("div", {
          staticClass: "thumbnail",
          style: { backgroundImage: "url(" + _vm.image + ")" },
        }),
      ]),
      _c("div", { staticClass: "details" }, [
        _c("div", { staticClass: "subject" }, [_vm._v(_vm._s(_vm.subject))]),
        _c("div", { staticClass: "divider" }),
        _vm.body
          ? _c("div", { staticClass: "body" }, [
              _vm._v(_vm._s(_vm._f("truncate")(_vm._f("striphtml")(_vm.body)))),
            ])
          : _vm._e(),
        _vm.dealership || _vm.position
          ? _c("div", { staticClass: "no-body" }, [
              _vm.position
                ? _c("div", { staticClass: "position" }, [
                    _vm._v(_vm._s(_vm.position)),
                  ])
                : _vm._e(),
              _vm.dealership
                ? _c("div", { staticClass: "dealership" }, [
                    _vm._v(_vm._s(_vm.dealership)),
                  ])
                : _vm._e(),
            ])
          : _vm._e(),
        _vm.byLine
          ? _c("div", { staticClass: "by-line" }, [
              _vm._v("By " + _vm._s(_vm.byLine)),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }