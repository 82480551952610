var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content-wrapper" }, [
    _c("div", { staticClass: "pgTitle" }, [_vm._v("Search Results")]),
    _c("div", { staticClass: "search" }, [_c("SearchResultList")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }